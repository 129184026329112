<template>
  <b-container class="body-container">
    <h1>Signed Out</h1>
    <p>You have been signed out.</p>
  </b-container>
</template>

<script>
export default {
  name: 'SignedOut'
};
</script>

<style scoped>

</style>
